body {
  /*font-family: sans-serif;*/
}

*,
*:before,
*:after {
  /* box-sizing: border-box; */
}

html,
body {
  /*margin:30px;*/
  /* margin: 0; */
  /* padding: 0; */
  /* height: 100vh; */
  /* width: 100vw; */
  /* overflow: hidden; */
}

body {
  /* display: flex; */
  /* justify-content: space-around; */
  /* align-items: center; */
}

#container {
  /* margin-right: 1em; */
  /* line-height: 0.9; */
}

h6 {
  font-weight: bold;
}
